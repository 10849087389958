import { computed } from 'vue';
import { useStoreGetters } from 'dashboard/composables/store';

/**
 * Composable to determine if the current user is an super administrator.
 * @returns {Boolean} - True if the current user is an super administrator, false otherwise.
 */
export function useSuperAdmin() {
  const getters = useStoreGetters();

  const email = computed(() => getters.getCurrentEmail.value);

  const isSuperAdmin = computed(() => email.value === 'admin@ialui.com.br');

  return {
    isSuperAdmin,
  };
}
